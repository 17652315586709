import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Logo from '~/components/Logo';
import Can from '~/components/Can';

import { signOut } from '~/store/modules/auth/actions';
import {
  Container,
  ContainerSandbox,
  Content,
  Profile,
  HeaderLink,
  LogoWrapper,
  VersionContainer,
  DropdownMenu,
  DropdownContent,
} from './styles';
// import logo from '~/assets/logo.png';

function Header() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.profile);
  const role = useSelector((state) => state.role.role);
  const [currentRoute, setCurrentRoute] = useState('dashboard');

  const handleSignOut = () => {
    dispatch(signOut());
  };

  return (
    <Container>
      {process.env.REACT_APP_IS_SANDBOX === 'true' && (
        <ContainerSandbox>Sandbox</ContainerSandbox>
      )}

      <Content>
        <nav>
          <Link to="/">
            <LogoWrapper>
              <Logo />
            </LogoWrapper>
          </Link>

          <HeaderLink active={currentRoute === 'dashboard'}>
            <Link to="/dashboard" onClick={() => setCurrentRoute('dashboard')}>
              DASHBOARD
            </Link>
          </HeaderLink>

          <Can
            role={role}
            perform="contracts:visit"
            yes={() => (
              <HeaderLink active={currentRoute === 'contracts'}>
                <Link
                  to="/contracts"
                  onClick={() => setCurrentRoute('contracts')}>
                  CONTRATOS
                </Link>
              </HeaderLink>
            )}
          />

          <Can
            role={role}
            perform="customers:visit"
            yes={() => (
              <HeaderLink active={currentRoute === 'customers'}>
                <Link
                  to="/customers"
                  onClick={() => setCurrentRoute('customers')}>
                  CLIENTES
                </Link>
              </HeaderLink>
            )}
          />

          {/* CADASTROS */}

          <DropdownMenu>
            <HeaderLink
              active={[
                'applications',
                'communications',
                'users',
                'external-configs',
                'modules',
                'versions',
              ].includes(currentRoute)}>
              CADASTROS
            </HeaderLink>

            <DropdownContent>
              <Can
                role={role}
                perform="applications:visit"
                yes={() => (
                  <HeaderLink active={currentRoute === 'applications'}>
                    <Link
                      to="/applications"
                      onClick={() => setCurrentRoute('applications')}>
                      APLICAÇÕES
                    </Link>
                  </HeaderLink>
                )}
              />

              <Can
                role={role}
                perform="communications:visit"
                yes={() => (
                  <HeaderLink active={currentRoute === 'communications'}>
                    <Link
                      to="/communications"
                      onClick={() => setCurrentRoute('communications')}>
                      COMUNICADOS
                    </Link>
                  </HeaderLink>
                )}
              />

              <Can
                role={role}
                perform="external_configs:visit"
                yes={() => (
                  <HeaderLink active={currentRoute === 'external-configs'}>
                    <Link
                      to="/external-configs"
                      onClick={() => setCurrentRoute('external-configs')}>
                      CONFIG. EXTERNAS
                    </Link>
                  </HeaderLink>
                )}
              />

              <Can
                role={role}
                perform="release-groups:visit"
                yes={() => (
                  <HeaderLink active={currentRoute === 'release-groups'}>
                    <Link
                      to="/release-groups"
                      onClick={() => setCurrentRoute('release-groups')}>
                      GRUPOS DE ATUALIZAÇÃO
                    </Link>
                  </HeaderLink>
                )}
              />

              <Can
                role={role}
                perform="modules:visit"
                yes={() => (
                  <HeaderLink active={currentRoute === 'modules'}>
                    <Link
                      to="/modules"
                      onClick={() => setCurrentRoute('modules')}>
                      MÓDULOS
                    </Link>
                  </HeaderLink>
                )}
              />

              <Can
                role={role}
                perform="partners:visit"
                yes={() => (
                  <HeaderLink active={currentRoute === 'partners'}>
                    <Link
                      to="/partners"
                      onClick={() => setCurrentRoute('partners')}>
                      PARCEIROS
                    </Link>
                  </HeaderLink>
                )}
              />

              <Can
                role={role}
                perform="users:visit"
                yes={() => (
                  <HeaderLink active={currentRoute === 'users'}>
                    <Link
                      to="/users"
                      active="true"
                      onClick={() => setCurrentRoute('users')}>
                      USUÁRIOS
                    </Link>
                  </HeaderLink>
                )}
              />

              <Can
                role={role}
                perform="versions:visit"
                yes={() => (
                  <HeaderLink active={currentRoute === 'versions'}>
                    <Link
                      to="/versions"
                      onClick={() => setCurrentRoute('versions')}>
                      VERSÕES
                    </Link>
                  </HeaderLink>
                )}
              />
            </DropdownContent>
          </DropdownMenu>

          {/* RELATÓRIOS */}

          <DropdownMenu>
            <HeaderLink
              active={[
                'nps-surveys',
                'exceptions',
                'exceptions-dashboard',
                'application-messages',
                'reports-contracts',
              ].includes(currentRoute)}>
              RELATÓRIOS
            </HeaderLink>
            <DropdownContent>
              <Can
                role={role}
                perform="exceptions:visit"
                yes={() => (
                  <HeaderLink active={currentRoute === 'application-messages'}>
                    <Link
                      to="/application-messages"
                      onClick={() => setCurrentRoute('application-messages')}>
                      AVISOS
                    </Link>
                  </HeaderLink>
                )}
              />

              <Can
                role={role}
                perform="contracts:visit"
                yes={() => (
                  <HeaderLink active={currentRoute === 'reports-contracts'}>
                    <Link
                      to="/reports/contracts"
                      onClick={() => setCurrentRoute('reports-contracts')}>
                      CONTRATOS
                    </Link>
                  </HeaderLink>
                )}
              />

              <Can
                role={role}
                perform="exceptions:visit"
                yes={() => (
                  <HeaderLink active={currentRoute === 'exceptions'}>
                    <Link
                      to="/exceptions"
                      onClick={() => setCurrentRoute('exceptions')}>
                      EXCEPTIONS
                    </Link>
                  </HeaderLink>
                )}
              />
              <Can
                role={role}
                perform="exceptions:visit"
                yes={() => (
                  <HeaderLink active={currentRoute === 'exceptions-dashboard'}>
                    <Link
                      to="/exceptions-dashboard"
                      onClick={() => setCurrentRoute('exceptions-dashboard')}>
                      EXCEPTIONS DASHBOARD
                    </Link>
                  </HeaderLink>
                )}
              />
              <Can
                role={role}
                perform="nps_surveys:visit"
                yes={() => (
                  <HeaderLink active={currentRoute === 'nps-surveys'}>
                    <Link
                      to="/nps-surveys"
                      onClick={() => setCurrentRoute('nps-surveys')}>
                      NPS
                    </Link>
                  </HeaderLink>
                )}
              />
            </DropdownContent>
          </DropdownMenu>
        </nav>

        <aside>
          <VersionContainer>v1.2.1</VersionContainer>
          <Profile>
            <div>
              <Link to="/profile/edit">
                <strong>{user && user.name}</strong>
              </Link>
              <Link to="/" onClick={handleSignOut}>
                Sair
              </Link>
            </div>
          </Profile>
        </aside>
      </Content>
    </Container>
  );
}

export default Header;
