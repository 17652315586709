import styled from 'styled-components';
import colors from '~/styles/colors';

export const Header = styled.header`
  margin-top: 30px;
  padding: 10px 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const BlockContainer = styled.div`
  flex: 1;
  margin-bottom: 30px;
`;

export const ChartContainer = styled.div``;

export const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const CountContainer = styled.div`
  background: ${() => colors.color_1};
  margin-left: 24px;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
