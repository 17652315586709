import React from 'react';
import PropTypes from 'prop-types';

import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import colors from '~/styles/colors';

import { Container } from './styles';

function AreaChartCustom({ title, data, dataKey, XDataKey, dataName }) {
  return (
    <Container>
      {title ? (
        <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>{title}</h2>
      ) : null}

      <ResponsiveContainer width="100%" height={300}>
        <AreaChart
          data={data}
          margin={{ top: 20, right: 30, bottom: 20, left: 0 }}>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={colors.color_3} stopOpacity={0.8} />
              <stop offset="95%" stopColor={colors.color_3} stopOpacity={0} />
            </linearGradient>
          </defs>

          <XAxis
            dataKey={XDataKey}
            // tickFormatter={(date) =>
            //   date.toLocaleDateString('pt-BR', {
            //     month: 'numeric',
            //     day: 'numeric',
            //   })
            // }
          />
          <YAxis />
          <Tooltip
            formatter={(value, name) => [`${value}`, `${name}`]}
            // labelFormatter={(label) =>
            //   label.toLocaleDateString('pt-BR', {
            //     month: 'long',
            //     day: 'numeric',
            //   })
            // }
          />

          <Legend />

          <Area
            type="monotone"
            dataKey={dataKey}
            stroke={colors.color_3}
            fillOpacity={1}
            fill="url(#colorUv)"
            strokeWidth={3}
            name={dataName}
            dot={{
              r: 3,
              stroke: colors.color_3,
              strokeWidth: 2,
              fill: colors.color_3,
            }}
          />
        </AreaChart>
      </ResponsiveContainer>
    </Container>
  );
}

AreaChartCustom.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dataKey: PropTypes.string.isRequired,
  XDataKey: PropTypes.string.isRequired,
  dataName: PropTypes.string,
};

AreaChartCustom.defaultProps = {
  title: undefined,
  dataName: undefined,
};

export default AreaChartCustom;
