import React from 'react';
import PropTypes from 'prop-types';

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import colors from '~/styles/colors';

import { Container } from './styles';

function BarChartCustom({ title, data, dataKey, dataName }) {
  return (
    <Container>
      {title ? (
        <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>{title}</h2>
      ) : null}

      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, bottom: 20, left: 0 }}>
          <XAxis
            dataKey="date"
            // tickFormatter={(date) =>
            //   date.toLocaleDateString('pt-BR', {
            //     month: 'numeric',
            //     day: 'numeric',
            //   })
            // }
          />
          <YAxis />
          <Tooltip
            formatter={(value, name) => [`${value}`, `${name}`]}
            // labelFormatter={(label) =>
            //   label.toLocaleDateString('pt-BR', {
            //     month: 'long',
            //     day: 'numeric',
            //   })
            // }
          />

          <Legend />

          <Bar
            dataKey={dataKey}
            fill={colors.color_3}
            stroke={colors.color_3}
            strokeWidth={3}
            name={dataName}
            radius={[4, 4, 0, 0]} // Opcional: bordas arredondadas nas barras
          />
        </BarChart>
      </ResponsiveContainer>
    </Container>
  );
}

BarChartCustom.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dataKey: PropTypes.string.isRequired,
  dataName: PropTypes.string,
};

BarChartCustom.defaultProps = {
  title: undefined,
  dataName: undefined,
};

export default BarChartCustom;
