import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { MdRefresh } from 'react-icons/md';
import {
  format,
  isThisYear,
  parseISO,
  subYears,
  startOfYesterday,
  endOfYesterday,
} from 'date-fns';

import api from '~/services/api';
import history from '~/services/history';

import { Button } from '~/components/Buttons/Button';
import { BaseContainer } from '~/components/BaseContainer';
import { Title } from '~/components/Title';
import AreaChart from '~components/Charts/AreaChart';
import BarChart from '~components/Charts/BarChart';
import { Table } from '~components/Table';

import checkPermission from '~/lib/checkPermission';
import getErrorMessage from '~/lib/getErrorMessage';
import colors from '~/styles/colors';

import {
  Header,
  ChartContainer,
  HorizontalContainer,
  BlockContainer,
  CountContainer,
} from './styles';

function ExceptionDashboard() {
  const role = useSelector((state) => state.role.role);
  const [exceptionsByDay, setExceptionsByDay] = useState([]);
  const [exceptionsByDayFiltered, setExceptionsByDayFiltered] = useState([]);
  const [groupedExceptions, setGroupedExceptions] = useState([]);
  const time = useRef(null);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    async function loadExceptionsByDay() {
      try {
        setLoading(true);

        const response = await api.get('reports/exceptions/exceptions-by-day', {
          params: {
            startDate: format(subYears(new Date(), 1), 'yyyy-MM-dd'),
            endDate: format(new Date(), 'yyyy-MM-dd'),
          },
        });

        const byDay = response.data.map((ex) => {
          const parsedDate = parseISO(ex.date);
          return {
            ...ex,
            dateParsed: parsedDate,
            dateFormatted: isThisYear(parsedDate)
              ? format(parsedDate, 'dd/MM')
              : format(parsedDate, 'dd/MM/yyyy'),
            count: Number(ex.count),
          };
        });

        const len = byDay.length;
        const filtered = byDay.sort().slice(len - 90, len);

        setExceptionsByDay(byDay);
        setExceptionsByDayFiltered(filtered);
        setLoading(false);
      } catch (err) {
        toast.error(
          <div>
            Falha ao carregar dados! <br /> <br />
            {getErrorMessage(err)}
          </div>
        );
      }
      return true;
    }

    async function loadExceptionsByMessage() {
      try {
        setLoading(true);

        const response = await api.get('exceptions', {
          params: {
            pageLimit: 200,
            startDate: format(startOfYesterday(), 'yyyy-MM-dd'),
            endDate: format(endOfYesterday(), 'yyyy-MM-dd'),
          },
        });

        // Agrupa as exceptions por message
        const grouped = response.data.reduce((acc, exception) => {
          const { message } = exception;
          acc[message] = (acc[message] || 0) + 1;
          return acc;
        }, {});

        // Converte para um array com o formato desejado
        const groups = Object.entries(grouped).map(([message, count]) => ({
          message,
          count,
        }));

        // Ordena grupos por contagem (maior para menor)
        const sortedGroups = groups.sort((a, b) => b.count - a.count);

        // Limita
        const topGroups = sortedGroups.slice(0, 20);

        setGroupedExceptions(topGroups);
        setLoading(false);
      } catch (err) {
        toast.error(
          <div>
            Falha ao carregar dados! <br /> <br />
            {getErrorMessage(err)}
          </div>
        );
      }
      return true;
    }

    /**
     * Check permissions
     */
    if (role && !checkPermission(role, 'exceptions:visit')) {
      history.push('/denied');
      return;
    }

    clearTimeout(time.current);
    time.current = setTimeout(() => {
      loadExceptionsByDay();
      loadExceptionsByMessage();
    }, 600);
  }, [role, refresh]);

  const exceptionsByMonth = useMemo(() => {
    if (!exceptionsByDay) return [];

    // Agrupar dados por mês
    const groupedDataByMonth = exceptionsByDay.reduce((acc, entry) => {
      const monthYear = new Date(entry.date).toLocaleDateString('pt-BR', {
        month: '2-digit',
        year: 'numeric',
      });

      acc[monthYear] = (acc[monthYear] || 0) + Number(entry.count);
      return acc;
    }, {});

    const dataByMonth = Object.entries(groupedDataByMonth).map(
      ([date, count]) => ({
        date,
        count,
      })
    );

    return dataByMonth;
  }, [exceptionsByDay]);

  const exceptionsByWeek = useMemo(() => {
    if (!exceptionsByDay) return [];

    // Função auxiliar para obter o número da semana e o ano
    const getWeekYear = (date) => {
      const d = new Date(date);
      const year = d.getFullYear();

      // Definir a data para a quinta-feira da semana atual para calcular o número da semana de acordo com o ISO 8601
      d.setDate(d.getDate() + 4 - (d.getDay() || 7));
      const startYear = new Date(d.getFullYear(), 0, 1);
      const week = Math.ceil(((d - startYear) / 86400000 + 1) / 7);

      return `${year}-W${String(week).padStart(2, '0')}`; // Formato "YYYY-WW"
    };

    // Agrupar dados por semana
    const groupedDataByWeek = exceptionsByDay.reduce((acc, entry) => {
      const weekYear = getWeekYear(entry.date);

      acc[weekYear] = (acc[weekYear] || 0) + Number(entry.count);
      return acc;
    }, {});

    // Transformar dados em um array compatível para o gráfico
    const dataByWeek = Object.entries(groupedDataByWeek).map(
      ([date, count]) => ({
        date,
        count,
      })
    );

    return dataByWeek;
  }, [exceptionsByDay]);

  const handleRefresh = () => {
    setLoading(true);
    setRefresh(!refresh);
  };

  return (
    <BaseContainer>
      <Title>Exceptions Dashboard</Title>

      <Header>
        <div />
        <Button
          type="button"
          padding="6px"
          disabled={loading}
          onClick={handleRefresh}>
          <MdRefresh size={24} color={colors.iconLight} />
        </Button>
      </Header>

      <BlockContainer>
        <BlockContainer>
          <ChartContainer>
            <AreaChart
              title="Exceptions Por Dia"
              data={exceptionsByDayFiltered}
              dataKey="count"
              XDataKey="dateFormatted"
              dataName="Exceptions"
            />
          </ChartContainer>
        </BlockContainer>

        <HorizontalContainer>
          <BlockContainer>
            <ChartContainer>
              <BarChart
                title="Exceptions Por Semana"
                data={exceptionsByWeek}
                dataKey="count"
                dataName="Exceptions"
              />
            </ChartContainer>
          </BlockContainer>

          <BlockContainer>
            <ChartContainer>
              <BarChart
                title="Exceptions Por Mês"
                data={exceptionsByMonth}
                dataKey="count"
                dataName="Exceptions"
              />
            </ChartContainer>
          </BlockContainer>
        </HorizontalContainer>
      </BlockContainer>

      <BlockContainer>
        <Title>Top Exceptions de Ontem</Title>
        <Table style={{ marginTop: 16, width: '100%' }}>
          {/* <thead>
              <tr>
                <th>Exception</th>
                <th>Total</th>
              </tr>
            </thead> */}
          <tbody>
            {groupedExceptions.map((item) => (
              <tr key={item.message}>
                <td style={{ textAlign: 'left' }}>{item.message}</td>
                <td>
                  <CountContainer>
                    <strong>{item.count}</strong>
                  </CountContainer>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </BlockContainer>
    </BaseContainer>
  );
}

export default ExceptionDashboard;
